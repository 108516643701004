import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E2E2E2',
    },
    secondary: {
      main: '#CFCFCF',
    },
    background: {
      default: '#F0F0F0',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F0F0F0',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#E2E2E2',
          color: '#333333',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#333333',
        },
      },
    },
  },
});

export default theme;