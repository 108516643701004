import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, Paper, TextField, Button, List, ListItem, Typography, 
  AppBar, Toolbar, Box, Chip
} from '@mui/material';
import { Send as SendIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

const SUGGESTED_QUESTIONS = [
  "Hvem er Dwarf, og hvad laver de?", // Who is Dwarf, and what do they do?
  "Hvem kan jeg kontakte for at tale om et projekt?", // Who can I get in touch with to talk about a project?
  "Hvilke projekter har Dwarf arbejdet på for nylig?", // Which cases have Dwarf been working on lately?
  "Kan Dwarf hjælpe min virksomhed med at udvikle en app?"
];

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSend = async (text = input) => {
    if (text.trim() === '') return;

    const newMessage = { text, sender: 'user' };
    setMessages(prev => [...prev, newMessage]);
    setInput('');
    setIsLoading(true);
    setShowSuggestions(false);

    try {
      const response = await fetch('http://165.22.199.75/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: text,
          conversation_history: messages.map(m => ({ role: m.sender, content: m.text }))
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = { text: '', sender: 'assistant' };
      setMessages(prev => [...prev, assistantMessage]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        assistantMessage.text += chunk;
        setMessages(prev => [...prev.slice(0, -1), { ...assistantMessage }]);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { text: 'Sorry, there was an error processing your request.', sender: 'assistant' };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestart = () => {
    setMessages([]);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (question) => {
    handleSend(question);
  };

  const SuggestedQuestions = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
      {SUGGESTED_QUESTIONS.map((question, index) => (
        <Chip
          key={index}
          label={question}
          onClick={() => handleSuggestionClick(question)}
          sx={{ cursor: 'pointer' }}
        />
      ))}
    </Box>
  );

  const renderMessage = (message) => {
    if (message.sender === 'assistant') {
      return (
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />
          }}
        >
          {message.text}
        </ReactMarkdown>
      );
    }
    return message.text;
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      backgroundColor: '#F0F0F0',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <img 
            src="https://bureauoversigten.dk/img/transparant-triangle-w-text.png" 
            alt="Logo" 
            style={{ height: '40px', marginRight: '20px' }} 
          />
          <Typography variant="h6" component="div">
            AI-Powered Search
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ mt: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '20px', backgroundColor: '#FFFFFF' }}>
          <List sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
            {messages.map((message, index) => (
              <ListItem key={index} sx={{
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 1
              }}>
                <Paper elevation={1} sx={{
                  padding: '10px',
                  maxWidth: '70%',
                  backgroundColor: message.sender === 'user' ? '#E2E2E2' : '#F0F0F0'
                }}>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {message.sender === 'user' ? 'You' : message.sender === 'assistant' ? 'Assistant' : 'System'}
                  </Typography>
                  <Typography component="div">
                    {renderMessage(message)}
                  </Typography>
                </Paper>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
          {showSuggestions && messages.length === 0 && <SuggestedQuestions />}
          <Box sx={{ display: 'flex', marginTop: '20px' }}>
            <TextField
              fullWidth
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
              disabled={isLoading}
              placeholder="Skriv din besked her..."
              sx={{ mr: 1, backgroundColor: '#F0F0F0' }}
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              onClick={() => handleSend()}
              disabled={isLoading}
            >
              Send
            </Button>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<RefreshIcon />}
            onClick={handleRestart}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
          >
            Genstart chat
          </Button>
        </Paper>
      </Container>
    </Box>
  );
};

export default ChatInterface;